.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.success {
  color: green;
}

.failure {
  color: red;
}

.popup {
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 40%;
  z-index: 9999;
  border: 2px solid rgb(220, 220, 220);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 20px;
}
